import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import WirUeberUnsSubmenu from "../../components/wirueberuns/wirueberuns_submenu"
import WirUeberUnsContentHeader from "../../components/wirueberuns/wirueberuns_contentHeader"
import "../../components/layout.css"
import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const meilensteine = ({data}) => (
  <Layout>
    <SEO title="Meilensteine"/>

    <WirUeberUnsContentHeader headline="UNSERE MEILENSTEINE" motto={data.allSanitySiteSettings.nodes[0].motto} />

    <section id="content_2_schule">
      <WirUeberUnsSubmenu />

      <article id="con_2_rechts">

        <h3 className="small_sub">
          <span style={{ color: "#007c34" }}>MEILENSTEINE</span>
        </h3>

        <p className="text">
          <span style={{ fontWeight: "bold" }}>Wichtige Meilensteine auf dem Weg durch das Grundschulleben an unserer Schule:</span>
        </p>
        <br/>
        <h4 className="xsmall_sub">Im ersten Schuljahr:</h4>

        <ul>
          <li className="aufzaehlung_gruener_kreis">Patenmodell</li>
          <li className="aufzaehlung_gruener_kreis">Verhaltenstraining f&uuml;r Schulanf&auml;nger</li>
          <li className="aufzaehlung_gruener_kreis">F&ouml;rderung im &quot;Lernstudio&quot;</li>
          <li className="aufzaehlung_gruener_kreis">Musikunterricht in Kooperation mit der Musikschule</li>
          <li className="aufzaehlung_gruener_kreis">Klassenfest</li>
          <li className="aufzaehlung_gruener_kreis">Ausfl&uuml;ge</li>
          <li className="aufzaehlung_gruener_kreis">Gesundheitsf&ouml;rderung &quot;Klasse 2000&quot;</li>
        </ul>
        <br/>

        <h4 className="xsmall_sub">Im zweiten Schuljahr:</h4>

        <ul>
          <li className="aufzaehlung_gruener_kreis">Klassengemeinschaftstage</li>
          <li className="aufzaehlung_gruener_kreis">Projekt mit der Jugendkunstschule</li>
          <li className="aufzaehlung_gruener_kreis">Zoobesuch</li>
          <li className="aufzaehlung_gruener_kreis">Klassenfest</li>
          <li className="aufzaehlung_gruener_kreis">Gesundheitsf&ouml;rderung &quot;Klasse 2000&quot;</li>
        </ul>
        <br/>

        <h4 className="xsmall_sub">Im dritten Schuljahr:</h4>

        <ul>
          <li className="aufzaehlung_gruener_kreis">B&uuml;chereibesuch</li>
          <li className="aufzaehlung_gruener_kreis">Freilichtmuseumsbesuch (Vom Korn zum Brot)</li>
          <li className="aufzaehlung_gruener_kreis">Schwimmunterricht</li>
          <li className="aufzaehlung_gruener_kreis">Projekt: Mein K&ouml;rper geh&ouml;rt mir (alternativ im 4.
            Schuljahr)
          </li>
          <li className="aufzaehlung_gruener_kreis">Klassenfest</li>
          <li className="aufzaehlung_gruener_kreis"> Gesundheitsf&ouml;rderung &quot;Klasse 2000&quot; (ab Schuljahr
            2013/14)
          </li>
        </ul>
        <br/>

        <h4 className="xsmall_sub">Im vierten Schuljahr:</h4>
        <ul>
          <li className="aufzaehlung_gruener_kreis">Radfahrausbildung</li>
          <li className="aufzaehlung_gruener_kreis">Mehrt&auml;gige Klassenreise</li>
          <li className="aufzaehlung_gruener_kreis">Projekt: Mein K&ouml;rper geh&ouml;rt mir (alternativ im 3.
            Schuljahr)
          </li>
          <li className="aufzaehlung_gruener_kreis">Heimatmuseum L&ouml;hne</li>
          <li className="aufzaehlung_gruener_kreis">Abschlussfahrt und -fest</li>
          <li className="aufzaehlung_gruener_kreis">Besuch der Philharmonie</li>
          <li className="aufzaehlung_gruener_kreis">Gesundheitsf&ouml;rderung &quot;Klasse 2000&quot; (ab
            Schuljahr 2014/15)
          </li>
        </ul>

      </article>


      <TermineMargin/>

    </section>
  </Layout>
)

export default meilensteine
